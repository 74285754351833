import axios from "axios";
export async function login(email,password) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    const response = await axios.post(`https://llenxback.plouidy.fr/api/v1/user/authenticate`,formData)
    return response;
}
export async function lidars(token) {
    const config = {
        headers:{
            Authorization: "Bearer "+token,
        }
    };
    const response = await axios.get(`https://llenxback.plouidy.fr/api/v1/lidars`,config )
    return response;
}
export async function lidar(token,id) {
    const config = {
        headers:{
            Authorization: "Bearer "+token,
        }
    };
    const response = await axios.get(`https://llenxback.plouidy.fr/api/v1/lidars/`+id,config )

    return response;
}
export async function site(token,id) {
    const config = {
        headers:{
            Authorization: "Bearer "+token,
        }
    };
    const response = await axios.get(`https://llenxback.plouidy.fr/api/v1/sites/`+id,config )

    return response;
}
export async function sites(token) {
    const config = {
        headers:{
            Authorization: "Bearer "+token,
        }
    };
    const response = await axios.get(`https://llenxback.plouidy.fr/api/v1/sites`,config )
    return response;
}