<template>
  <div class="h-screen overflow-y-hidden">
    <NavBarView :items="[ {name: $t('message.home'),action: 'home',id: '1'}, {name: $t('message.logout'),action: 'logout',id: '2'}]"/>
    <div class="bg-gray-200 text-gray-200 py-3.5 px-6 shadow md:flex justify-between
  items-center">
      <h1 class="text-black">{{ this.site.name }}</h1>
      <ReplayView ref="replayComponent"/>
      <div class="text-black">{{ date }}</div>
      <div class="flex space-x-4">
        <button
            @click="liveClick()"
            :class="['py-2 px-4 rounded', isSelected('live') ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black']"
        >
          Live
        </button>
        <button
            @click="replayClick()"
            :class="['py-2 px-4 rounded', isSelected('replay') ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black']"
        >
          Replay
        </button>
      </div>
    </div>
    <div>
      <aside id="sidebar-multi-level-sidebar"
             class="fixed left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0"
             aria-label="Sidebar">

        <div class="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
          <div class="flex space-x-4 mb-5">
            <button
                @click="setCameraMode('v1')"
                :class="['py-2 px-4 rounded', isViewSelected('v1') ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black']"
            >1</button>
            <button
                @click="setCameraMode('v2')"
                :class="['py-2 px-4 rounded', isViewSelected('v2') ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black']"
            >2</button>
            <button
                @click="setCameraMode('v3')"
                :class="['py-2 px-4 rounded', isViewSelected('v3') ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black']"
            >3</button>
            <button
                @click="setCameraMode('v4')"
                :class="['py-2 px-4 rounded', isViewSelected('v4') ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black']"
            >4</button>
          </div>


          <ul class="space-y-1 font-medium bg-blue-200 p-2">
            <li class="w-full flex" > <span  class="w-1/2 font-semibold">{{$t('message.pitch')}} :</span> <span  class="w-1/2" id = "display_pitch" >-</span></li>
            <li class="w-full flex"> <span  class="w-1/2 font-semibold">{{$t('message.latitude')}} :</span> <span  class="w-1/2" id = "display_lat" >-</span></li>
            <li class="w-full flex"> <span  class="w-1/2 font-semibold">{{$t('message.longitude')}} :</span> <span  class="w-1/2" id = "display_lon" >-</span></li>
            <li class="w-full flex"> <span  class="w-1/2 font-semibold">{{$t('message.wheel')}} :</span> <span  class="w-1/2" id = "display_wheel" >-</span></li>
            <li class="w-full flex"> <span  class="w-1/2 font-semibold">{{$t('message.left')}} :</span> <span  class="w-1/2" id = "display_left" >-</span></li>
            <li class="w-full flex"> <span  class="w-1/2 font-semibold">{{$t('message.right')}} :</span> <span  class="w-1/2" id = "display_right" >-</span></li>
          </ul>
        </div>
      </aside>
      <TwinView/>
    </div>
  </div>
</template>

<script>
import {GET_TOKEN, SET_AUTHENTICATION, SET_TOKEN} from "@/store/storeconstants";
import {site} from "@/api/Services";
import NavBarView from "@/components/NavBarView.vue";
import TwinView from "@/components/TwinView.vue";
import realtime from "@/classes/realtime";
import ReplayView from "@/components/ReplayView.vue";

export default {

  name: 'TerrainView',
  components: {ReplayView, TwinView, NavBarView},
  data() {
    return {
      site: {
        name: "",
      },
      peer_connected: false,
      connected_status: false,
      connection: null,
      //TODO Hardcode
      peer_id: 1,
      date:"-",
      selectedButton: "live",
      viewMode: "v1",
      replay_status: "off",
    };
  },
  methods: {
    setCameraMode(mode) {
      this.viewMode = mode;
      this.emitter.emit("camera_position",mode);

    },
    replayClick() {
      this.selectedButton = "replay"
      this.date ="-";
      this.$refs.replayComponent.replay_pause();
      this.replay_status="paused";
    },
    liveClick() {
      this.$refs.replayComponent.live();
      this.selectedButton= "live"
      this.replay_status="off"

    },
    isSelected(button) {
      return this.selectedButton === button;
    },
    isViewSelected(mode) {
      return this.viewMode === mode;
    },
    getSiteInfo(siteId) {
      let token = this.$store.getters[`auth/${GET_TOKEN}`];
      site(token, siteId).then((result) => {
        this.$nextTick(() => {
          this.site.name = result.data.site.name;
          this.emitter.emit("site_config", result.data.site);
        });
      });
    },
    logout() {
      this.$store.commit(`auth/${SET_AUTHENTICATION}`, false);
      this.$store.commit(`auth/${SET_TOKEN}`, "");
      window.sessionStorage.clear();
      this.$router.push('/login')
    },
    clear() {
      // let foo = document.getElementById("contenu");
      // foo.innerHTML = "";
    },
    sendParams() {
      this.connection.send(JSON.stringify({
      }));
    },

    isConnected: function () {
      return false;
    },
  },
  mounted() {
    this.getSiteInfo(this.$route.params.id);
    let connectionCallback = (status) => {
      const el = document.querySelector('#indicator');
      el.classList.remove(status ? "text-red-500" : "text-green-500");
      el.classList.add(status ? "text-green-500" : "text-red-500");

    }
    realtime.setUserConnectionCallback(connectionCallback);
    realtime.getUserConnectionStatus();

    let dataCallback = (parsedObject) => {
      let self = this;
      if (self.replay_status == "paused"){
        return
      }

      // message date displayed when location status is received
      if (parsedObject.type === "position_status") {
        let d = new Date(parsedObject.timestamp);
        let options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

        this.date = d.toLocaleDateString(this.$t('options.locale'), options) + " " + d.toLocaleTimeString();
      }
      if(parsedObject.type === "position_status"){
        this.replay_status = parsedObject.value;
      }
      switch (parsedObject.type) {
        case "position_status":
        case "wheel_status":
        case "rotation_status":
        case "points_cloud":
          if (parsedObject.param == self.peer_id) {
            let obj = JSON.parse(parsedObject.value);
            this.emitter.emit(parsedObject.type, obj);
          }
          break;
      }
    }
    this.emitter.on("replay_status", obj => {
      this.replay_status=obj;
    });
    realtime.setDataCallback(dataCallback);
  },

}
</script>